import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContentRows from '../components/ContentRows/ContentRows';
import ContentRowJSON from '../data/content-rows.json';
import ParallaxBlock from '../components/ParallaxBlock/ParallaxBlock';

import Hero from '../components/Hero/Hero';
import Packages from '../components/Packages/Packages';
import Benefits from '../components/Benefits/Benefits';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Footer from '../components/Footer/Footer';
import { BtnMore, BtnMoreAnchor } from '../components/Hero/Hero';

import ParallaxImg from '../assets/images/ParallaxImg.png';

const StyledBtnMore = styled(BtnMore)`
  display: inline-block;
  margin: 60px auto 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 50px auto 0;
  }
`;

const StyledBtnMoreAnchor = styled(BtnMoreAnchor)`
  display: inline-block;
  margin: 60px auto 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 50px auto 0;
  }
`;

// eslint-disable-next-line react/display-name
export default () => {
  const [txtValue, setTxtValue] = useState('');

  return (
    <Layout>
      <SEO title="Kompleksowa Obsługa Twojego Sklepu Merchandise" />
      <Hero />
      <ContentRows
        id="dla-kogo"
        imgFirst
        data={ContentRowJSON[0]}
        isLottie
        viewBoxSize="110 50 992 870"
        viewBoxOnly={true}
      />
      <HowItWorks heading={true} shouldAnimate={true}>
        <StyledBtnMoreAnchor to="/jak-to-dziala/#dowiedz-sie-wiecej">
          Więcej o procesie
        </StyledBtnMoreAnchor>
      </HowItWorks>
      <ContentRows
        data={ContentRowJSON[1]}
        noPaddingBottom
        noPaddingTop
        isLottie
        viewBoxSize="120 240 1042 1150"
        viewBoxOnly={true}
      />
      <Benefits showButton={true} />
      <ParallaxBlock background={ParallaxImg}>
        <ParallaxBlock.HeadingSm>
          Przejrzyste zasady współpracy
        </ParallaxBlock.HeadingSm>
        <ParallaxBlock.Paragraph>
          U nas nie ma ukrytych kosztów. Nie płacisz żadnej opłaty na start.
          Jedyna opłata, jaką pobieramy, to uzgodniona z Tobą prowizja od
          wygenerowanej sprzedaży.
        </ParallaxBlock.Paragraph>
      </ParallaxBlock>
      <Packages setTxtValue={setTxtValue} />
      <ContentRows
        imgFirst
        data={ContentRowJSON[2]}
        isLottie
        viewBoxSize="130 300 992 790"
        viewBoxOnly={true}
      />
      <Footer txtValue={txtValue}>
        <Footer.Heading>
          <strong>Skontaktuj się</strong> z nami
        </Footer.Heading>
        <Footer.Paragraph>
          Blogerze! Youtuberze! Influencerze! Czekamy aż do nas napiszesz lub
          zadzwonisz. Jak się do nas nie odezwiesz, to trudno będzie nam Ci
          pomóc!
        </Footer.Paragraph>
      </Footer>
    </Layout>
  );
};
